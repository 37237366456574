import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import RatingStar from "./RatingStar";
import Smiles from "./Smiles";
import { ReactComponent as Joyful } from "../../images/smiles/joyful_smiley.svg";
import { ReactComponent as JoyfulOutlined } from "../../images/smiles/joyful_smiley_outline.svg";
import { ReactComponent as Funny } from "../../images/smiles/funny_smiley.svg";
import { ReactComponent as FunnyOutlined } from "../../images/smiles/funny_smiley_outline.svg";
import { ReactComponent as Happy } from "../../images/smiles/happy_smiley.svg";
import { ReactComponent as HappyOutlined } from "../../images/smiles/happy_smiley_outline.svg";
import { ReactComponent as Touched } from "../../images/smiles/touched_smiley.svg";
import { ReactComponent as TouchedOutlined } from "../../images/smiles/touched_smiley_outline.svg";
import { ReactComponent as Surprised } from "../../images/smiles/surprised_smiley.svg";
import { ReactComponent as SurprisedOutlined } from "../../images/smiles/surprised_smiley_outline.svg";
import { ReactComponent as Sad } from "../../images/smiles/sad_smiley.svg";
import { ReactComponent as SadOutlined } from "../../images/smiles/sad_smiley_outline.svg";
import { ReactComponent as Stolid } from "../../images/smiles/stolid_smiley.svg";
import { ReactComponent as StolidOutlined } from "../../images/smiles/stolid_smiley_outline.svg";
import { ReactComponent as Annoyed } from "../../images/smiles/annoyed_smiley.svg";
import { ReactComponent as AnnoyedOutlined } from "../../images/smiles/annoyed_smiley_outline.svg";
import { ReactComponent as Angry } from "../../images/smiles/angry_smiley.svg";
import { ReactComponent as AngryOutlined } from "../../images/smiles/angry_smiley_outline.svg";
import { ReactComponent as Frustrated } from "../../images/smiles/frustrated_smiley.svg";
import { ReactComponent as FrustratedOutlined } from "../../images/smiles/frustrated_smiley_outline.svg";

export default function FeedComment(props) {
  const [overJ, setOverJ] = React.useState(false);
  const [overZ, setOverZ] = React.useState(false);
  const [selectedJ, setSelectedJ] = React.useState(false);
  const [selectedZ, setSelectedZ] = React.useState(false);

  const [starIndex, setStarIndex] = React.useState(-1);
  const handeStarClick = (i) => {
    i === starIndex ? setStarIndex(-1) : setStarIndex(i);
  };

  const [smileIndex, setSmileIndex] = React.useState(-1);
  const handeSmileClick = (i) => {
    i === smileIndex ? setSmileIndex(-1) : setSmileIndex(i);
  };

  const handleSelectJ = () => {
    setOverZ(false);
    setSelectedZ(false);

    setSelectedJ((selectedJ) => !selectedJ);
  };

  const handleSelectZ = () => {
    setOverJ(false);
    setSelectedJ(false);

    setSelectedZ((selectedZ) => !selectedZ);
  };

  return (
    <Box>
      <Typography
        variant="h1"
        display="flex"
        justifyContent="center"
        sx={{
          color: `${props.color}`,
          my: "13px",
        }}
      >
        WHAT DO YOU THINK?
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <RatingStar
          color={props.color}
          opacity="0.2"
          ratingText="BAD"
          ratingPercent="20%"
          index={1}
          starIndex={starIndex}
          onClick={() => handeStarClick(1)}
        />

        <RatingStar
          color={props.color}
          opacity="0.4"
          ratingText="OK"
          ratingPercent="40%"
          index={2}
          starIndex={starIndex}
          onClick={() => handeStarClick(2)}
        />

        <RatingStar
          color={props.color}
          opacity="0.6"
          ratingText="GOOD"
          ratingPercent="60%"
          index={3}
          starIndex={starIndex}
          onClick={() => handeStarClick(3)}
        />

        <RatingStar
          color={props.color}
          opacity="0.8"
          ratingText="GREAT"
          ratingPercent="80%"
          index={4}
          starIndex={starIndex}
          onClick={() => handeStarClick(4)}
        />

        <RatingStar
          color={props.color}
          opacity="1"
          ratingText="WOW"
          ratingPercent="100%"
          index={5}
          starIndex={starIndex}
          onClick={() => handeStarClick(5)}
        />
      </Stack>
      <Typography
        variant="h1"
        display="flex"
        justifyContent="center"
        sx={{
          color: `${props.color}`,
          my: "10px",
        }}
      >
        HOW DO YOU FEEL?
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Smiles
          color={props.color}
          smileText="JOYFUL"
          component={<Joyful fill={props.color} />}
          componentOutlined={<JoyfulOutlined fill={props.color} />}
          index={1}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(1)}
        />

        <Smiles
          color={props.color}
          smileText="FUNNY"
          component={<Funny fill={props.color} />}
          componentOutlined={<FunnyOutlined fill={props.color} />}
          index={2}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(2)}
        />

        <Smiles
          color={props.color}
          smileText="HAPPY"
          component={<Happy fill={props.color} />}
          componentOutlined={<HappyOutlined fill={props.color} />}
          index={3}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(3)}
        />

        <Smiles
          color={props.color}
          smileText="TOUCHED"
          component={<Touched fill={props.color} />}
          componentOutlined={<TouchedOutlined fill={props.color} />}
          index={4}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(4)}
        />

        <Smiles
          color={props.color}
          smileText="SURPRISED"
          component={<Surprised fill={props.color} />}
          componentOutlined={<SurprisedOutlined fill={props.color} />}
          index={5}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(5)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Smiles
          color={props.color}
          smileText="SAD"
          component={<Sad fill={props.color} />}
          componentOutlined={<SadOutlined fill={props.color} />}
          index={6}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(6)}
        />

        <Smiles
          color={props.color}
          smileText="STOLID"
          component={<Stolid fill={props.color} />}
          componentOutlined={<StolidOutlined fill={props.color} />}
          index={7}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(7)}
        />

        <Smiles
          color={props.color}
          smileText="ANNOYED"
          component={<Annoyed fill={props.color} />}
          componentOutlined={<AnnoyedOutlined fill={props.color} />}
          index={8}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(8)}
        />

        <Smiles
          color={props.color}
          smileText="ANGRY"
          component={<Angry fill={props.color} />}
          componentOutlined={<AngryOutlined fill={props.color} />}
          index={9}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(9)}
        />

        <Smiles
          color={props.color}
          smileText="FRUSTRATED"
          component={<Frustrated fill={props.color} />}
          componentOutlined={<FrustratedOutlined fill={props.color} />}
          index={10}
          smileIndex={smileIndex}
          onClick={() => handeSmileClick(10)}
        />
      </Stack>
    </Box>
  );
}
