import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import {
  Stack,
  Box,
  Typography,
  Divider,
  Tabs,
  Tab,
  IconButton,
  SpeedDial,
  Fab,
} from "@mui/material";
import { ThemeProvider, styled, createTheme } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { podcastListPropTypes } from "./podcast-helpers";
import { slugify } from "../../helpers/string";
import Player from "../Player";
import "./podcast-page-style.css";
import Embed from "../Embed";
import { getTheme } from "../../helpers/theme";
import { ShareLinks } from "./ShareLinks";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

export default function PodcastPage({ feed }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [podcast, setPodcast] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const foundItem = feed.items.find((item) => slugify(item.title) === slug);
    setPodcast(foundItem);
  }, [feed, slug]);

  if (!podcast) {
    // TODO: 404
    return <></>;
  }

  return (
    <ThemeProvider theme={createTheme(getTheme("dark"))}>
      <Box>
        <Stack
          className="podcastPageHeader"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Fab
            variant="circular"
            size="medium"
            color="primary"
            aria-label="add"
            sx={{
              margin: 0,
              bottom: "auto",
              left: 30,
              top: 30,
              right: "auto",
              position: "fixed",
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIosIcon />
          </Fab>
          <Box
            className="podcastPageHeaderBg"
            alt={podcast.title}
            sx={{
              backgroundImage: `url(${feed.coverImage})`,
            }}
          />

          <Box
            className="podcastPageCover"
            component="img"
            alt="Podcast cover"
            src={podcast.coverImage}
          />
          <Typography className="podcastPageTitle">{feed.title}</Typography>
          <Typography className="podcastPageFeedTitle">
            {podcast.title}
          </Typography>
          <Box sx={{ mt: "5px", width: "80%" }}>
            <Player
              src={podcast.enclosure.url}
              image={podcast.coverImage}
              feedTitle={feed.title}
              link={`/${slugify(podcast.title)}`}
              title={podcast.title}
              compact={true}
            />
          </Box>
          <Typography sx={{ fontWeight: 400, fontSize: 14 }}>
            {podcast.duration} | {podcast.publicationDate}
          </Typography>
        </Stack>
        <Stack
          className="podcastPageBody"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box className="podcastPageBodyDescriptionBox">
            <Divider>
              <Typography className="podcastPageBodyDescriptionTitle">
                DESCRIPTION
              </Typography>
            </Divider>
            <Typography
              className="podcastPageBodyDescription"
              dangerouslySetInnerHTML={{ __html: podcast.description }}
            />
          </Box>

          {podcast.video && (
            <>
              <Typography variant="h1" sx={{ color: "#fff", mt: "27px" }}>
                Video
              </Typography>
              <Box sx={{ mt: "5px" }}>
                <iframe
                  title={podcast.title}
                  className="rumble"
                  src={podcast.video}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </Box>
            </>
          )}

          <Tabs value={value} onChange={handleChange}>
            <Tab sx={{ fontSize: 16 }} label="SHARE"></Tab>
            <Tab sx={{ fontSize: 16 }} label="EMBED" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ShareLinks />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Embed feedLink={feed.link} podcast={podcast} />
          </TabPanel>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}

PodcastPage.propTypes = podcastListPropTypes;

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
