import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import { ReactComponent as RatingStars } from "../../images/ratingStar.svg";
import { ReactComponent as FilledStars } from "../../images/filledRatingStar.svg";

export default function RatingStar(props) {
  const [over, setOver] = React.useState(false);

  const selected = props.index === props.starIndex;

  return (
    <Stack
      width={51}
      height={51}
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: "8px",
        bgcolor: "#313131",
      }}
    >
      <Box
        width={10}
        height={10}
        onMouseOver={() => setOver(true)}
        onMouseOut={() => setOver(false)}
        onClick={props.onClick}
        sx={{
          cursor: "pointer",
          ":hover": {
            filter: `drop-shadow(0 0 10px ${props.color});`,
          },
          ...(selected
            ? { filter: `drop-shadow(0 0 10px ${props.color});` }
            : ""),
        }}
      >
        {over || selected ? (
          <Box>
            <FilledStars fill={props.color} opacity={props.opacity} />
            {selected ? (
              <RatingStars
                fill={props.color}
                opacity="1"
                style={{ position: "absolute", top: 0, left: 0 }}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          <RatingStars fill={props.color} opacity={props.opacity} />
        )}
      </Box>
      <Typography
        variant="rating"
        sx={{
          textAlign: "center",
          color: `${props.color}`,
          mt: "5px",
          ...(over || selected
            ? { filter: `drop-shadow(0 0 10px ${props.color});` }
            : ""),
        }}
      >
        {props.ratingText}
        <br />
        <b>{props.ratingPercent}</b>
      </Typography>
    </Stack>
  );
}
