import { CALL_API } from "../../middleware/api";

export const FEED_REQUEST = 'FEED_REQUEST'
export const FEED_SUCCESS = 'FEED_SUCCESS'
export const FEED_FAILURE = 'FEED_FAILURE'

const fetchFeed = () => ({
  [CALL_API]: {
    types: [FEED_REQUEST, FEED_SUCCESS, FEED_FAILURE],
    endpoint: 'feed.xml',
  },
});

export const loadFeed = () => (dispatch, getState) => {
  const feed = getState().feed;
  if (feed.title) {
    return null;
  }

  return dispatch(fetchFeed());
}
