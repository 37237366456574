export const CALL_API = 'Call API';

console.log(process.env)

const API_ROOT = process.env.REACT_APP_API_URL || '/';

const callApi = (endpoint) => {
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint

  // return axios.get(fullUrl)
  //   .then(response => {
  //     console.log(response);
  //   });
  return fetch(fullUrl)
    .then(response =>
      response.text().then(data => {
        if (!response.ok) {
          return Promise.reject(data)
        }

        return data;
      })
    );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default store => next => action => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { types } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  }

  const [ requestType, successType, failureType ] = types;
  next(actionWith({ type: requestType }));

  return callApi(endpoint).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error.message || 'Something bad happened'
    }))
  );
}
