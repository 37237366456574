import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Stack,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Header from "./Header";
import { podcastListPropTypes } from "./podcast-helpers";
import "./style.css";
import { slugify } from "../../helpers/string";
import "./podcast-list-style.css";
import { getTheme } from "../../helpers/theme";

export default function PodcastList({ feed }) {
  const [loadedState, setLoadedState] = useState({
    hasMore: true,
    loadedCount: 100,
    loadedItems: feed.items.slice(0, 100),
  });
  const [search, setSearch] = useState("");

  const loadMore = () => {
    const subtracted = feed.items.length - (loadedState.loadedCount + 100);
    if (subtracted < 0) {
      setLoadedState({
        hasMore: false,
        loadedCount: feed.items.length,
        loadedItems: feed.items,
      });
    } else {
      const newLoadedCount = loadedState.loadedCount + 100;
      setLoadedState({
        hasMore: true,
        loadedCount: newLoadedCount,
        loadedItems: feed.items.slice(0, newLoadedCount),
      });
    }

    return;
  };

  const handleSearch = (e) => {
    const newItems = feed.items.filter((item) =>
      item.title.toLowerCase().includes(e.target.value.toLowerCase())
    );

    const moreThen100 = newItems.length > 100;
    setLoadedState({
      hasMore: moreThen100,
      loadedCount: moreThen100 ? 100 : newItems.length,
      loadedItems: newItems.slice(0, 100),
    });

    setSearch(e.target.value);
  };

  return (
    <ThemeProvider theme={createTheme(getTheme("dark"))}>
      <Container>
        <CssBaseline />
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <TextField
            label="Search podcasts"
            type="search"
            variant="standard"
            fullWidth
            value={search}
            onChange={handleSearch}
          />
        </Box>
        <Header
          title={feed.title}
          description={feed.description}
          coverImage={feed.coverImage}
          copyright={feed.copyright}
        />
        <Box padding={2}>
          {loadedState.loadedItems.map((item) => (
            <ListItem
              key={item.guid}
              guid={item.guid}
              title={item.title}
              subTitle={item.subTitle}
              publicationDate={item.publicationDate}
              coverImage={item.coverImage}
            />
          ))}
        </Box>
        {loadedState.hasMore && (
          <Button onClick={loadMore} variant="contained" fullWidth>
            Load more...
          </Button>
        )}
      </Container>
    </ThemeProvider>
  );
}

PodcastList.propTypes = podcastListPropTypes;

function ListItem({ guid, title, subTitle, publicationDate, coverImage }) {
  return (
    <Box sx={{ boxShadow: "rgb(255 0 0 / 10%) 0px 0px", marginBottom: 2 }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Box
          className="itemCover"
          component="img"
          alt={title}
          src={coverImage}
        />

        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Stack spacing={3}>
            <Typography
              className="itemTitle"
              component={Link}
              to={slugify(title)}
            >
              {title}
            </Typography>
            <Typography className="itemSubTitle">{subTitle}</Typography>
          </Stack>
          <p
            style={{ marginLeft: "auto", paddingRight: 10, paddingBottom: 10 }}
          >
            {publicationDate}
          </p>
        </Stack>
      </Stack>
    </Box>
  );
}

ListItem.propTypes = {
  guid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
};
