import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box, Container, Stack, Typography } from "@mui/material";
import ContentIcon from "../images/content_logo.svg";
import Financify from "../images/financify_icon_white.svg";
import EthereumWhite from "../images/ethereum_logo_white.svg";
import CryptoTriangle from "../images/crypto_triangle_green.svg";
import CryptoGraph from "../images/crypto_graph_default.svg";
import PostImgFirst from "../images/lobby_post_01.png";
import PostImgSecond from "../images/lobby_post_02.png";
import PostImgThird from "../images/lobby_post_03.png";
import AvatarAnna from "../images/user_avatar_social_anna.png";
import AvatarJoe from "../images/user_avatar_social_joe.png";
import AvatarAlan from "../images/user_avatar_social_alan.png";
import SocialActivities from "./helperComponents/SocialActivities";
import { Link } from "react-router-dom";

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const Lobby = (props) => {
  const [activeSocial, setActiveSocial] = React.useState(0);

  React.useEffect(() => {
    const id = setTimeout(() => {
      setActiveSocial((activeSocial + 1) % 3);
    }, getRandomIntInclusive(2500, 4000));

    return () => clearTimeout(id);
  }, [activeSocial]);

  return (
    <StyledEngineProvider injectFirst>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#000",
          height: props.covered ? "100vh" : "auto",
          overflow: props.covered ? "hidden" : "hidden",
        }}
      >
        <Typography variant="h2" sx={{ py: 3 }}>
          YOU CAN LOG IN...
        </Typography>

        <Stack direction="row" justifyContent="space-evenly">
          <Stack alignItems="center" component={Link} to="/feeds">
            <Box
              component="img"
              sx={{
                height: 46,
                width: 46,
              }}
              alt="dot content logo icon"
              src={ContentIcon}
            />
            <Typography variant="h3" sx={{ pt: 1, pb: 2 }}>
              CONTENT
            </Typography>
          </Stack>
          <Stack alignItems="center" component={Link} to="/feeds">
            <Box
              component="img"
              sx={{
                height: 46,
                width: 46,
              }}
              alt="dot content finance icon"
              src={Financify}
            />
            <Typography variant="h3" sx={{ pt: 1, pb: 2 }}>
              FINANCIFY
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="h2" sx={{ py: 3 }}>
          SINCE YOU'VE BEEN HERE...
        </Typography>

        <Stack direction="row" alignItems="center">
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="solder with a weapon"
            src={PostImgFirst}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h4">
              Mauripol has been "destroyed" and will take <br />
              "years to rebuild"
            </Typography>
            <Typography variant="h4" sx={{ color: "#77EAFF", mt: 0.3 }}>
              More...
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="cargo ship"
            src={PostImgSecond}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h4">
              Great Barrier Reef: Australia confirms new mass <br /> bleaching
              event
            </Typography>
            <Typography variant="h4" sx={{ color: "#77EAFF", mt: 0.3 }}>
              More...
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="blurred city from the top"
            src={PostImgThird}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h4">
              The three Russian cyber-attacks the West most <br /> fears
            </Typography>
            <Typography variant="h4" sx={{ color: "#77EAFF", mt: 0.3 }}>
              More...
            </Typography>
          </Box>
        </Stack>

        <Typography variant="h2" sx={{ py: 3 }}>
          CRYPTO
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box component="img" alt="ethereum logo white" src={EthereumWhite} />

          <Stack sx={{ mr: 3 }}>
            <Typography variant="h4">Ethereum</Typography>
            <Stack direction="row" sx={{ my: 0.4 }}>
              <Typography variant="paragraph">+ 0,1234</Typography>
              <Box
                component="img"
                alt="green crypto triangle"
                src={CryptoTriangle}
                sx={{ mx: 0.5, alignSelf: "center" }}
              />
              <Typography variant="paragraph" sx={{ color: "#c4ff00" }}>
                1,025%
              </Typography>
            </Stack>
            <Typography variant="paragraph">05.05.2022</Typography>
          </Stack>

          <Box
            component="img"
            alt="graph chart"
            src={CryptoGraph}
            sx={{ width: 170 }}
          />
        </Stack>

        <Typography variant="h2" sx={{ py: 3 }}>
          SOCIAL ACTIVITIES...
        </Typography>

        {activeSocial === 0 && (
          <SocialActivities
            avatar={AvatarAnna}
            alt="social media avatar Anna"
            text="Anna McLoskie sent you a friend request"
          />
        )}

        {activeSocial === 1 && (
          <SocialActivities
            avatar={AvatarAlan}
            alt="social media avatar Alan"
            text="You have been texted by Alan Wilder"
          />
        )}

        {activeSocial === 2 && (
          <SocialActivities
            avatar={AvatarJoe}
            alt="social media avatar joe"
            text="Joe Dixon sent you a friend request"
          />
        )}
      </Container>
    </StyledEngineProvider>
  );
};

export default Lobby;
