import React, { createRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import {
  styled,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  Grid,
  ButtonGroup,
} from "@mui/material";
import Player from "./Player";
import { getTheme } from "../helpers/theme";

import { slugify } from "../helpers/string";

export default function PlayerPage(props) {
  // const location = useLocation();
  const { guid } = useParams();
  // const search = location.pathname.replace("/player/", "");

  const item = props.feed.item.find((item) => item.guid._text === guid);

  if (!item) {
    return <></>;
    // TODO: 404
  }

  const title = item.title._text || item.title._cdata;

  const getImage = () => {
    const media = item["media:content"]._attributes;
    if (media.medium !== "image") {
      return null;
    }

    return media.url;
  };

  const getPodcast = () => {
    const media = item.enclosure._attributes;

    return media.url;
  };

  return (
    <ThemeProvider theme={createTheme(getTheme("dark"))}>
      <Player
        src={getPodcast()}
        image={getImage()}
        feedTitle={props.feed.title._cdata}
        link={`/${slugify(title)}`}
        title={title}
        guid={item.guid._text}
        feedLink={props.feed.link._text}
      />
    </ThemeProvider>
  );
}
