import React from "react";
import { Stack } from "@mui/material";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export function ShareLinks() {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
    >
      <FacebookShareButton url={getShareUrl()}>
        <FacebookIcon size={40} />
      </FacebookShareButton>
      <TwitterShareButton url={getShareUrl()}>
        <TwitterIcon size={40} />
      </TwitterShareButton>
      <LinkedinShareButton url={getShareUrl()}>
        <LinkedinIcon size={40} />
      </LinkedinShareButton>
      <WhatsappShareButton url={getShareUrl()}>
        <WhatsappIcon size={40} />
      </WhatsappShareButton>
      <EmailShareButton url={getShareUrl()}>
        <EmailIcon size={40} />
      </EmailShareButton>
    </Stack>
  );
}

function getShareUrl() {
  return window.location.href;
}
