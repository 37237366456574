import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Stack, Box, CircularProgress } from "@mui/material";
import loaderVideo from "../../video/content_demo_video.mp4";
import { getTheme } from "../../helpers/theme";
import Lobby from "../Lobby";
import Header from "../Header";
import UserProfile from "../UserProfile";
import Feeds from "../Feeds";
import FeedExtended from "../helperComponents/FeedExtended";
import FeedImageSoldier from "../../images/feedImage_soldier.png";
import FeedImageWomen from "../../images/feedImage_women_withHelmet.png";
import Defiant from "../Defiant";
import TechCommunity from "../TechCommunity";
import Civilians from "../Civilians";
import Ethereum from "../Ethereum";
import ImfStaff from "../ImfStaff";

import { loadFeed } from "../../store/actions";
import { connect } from "react-redux";
import Podcast from "../Podcast";
import PodcastList from "../PodcastList/PodcastList";
import PodcastPage from "../PodcastList/PodcastPage";
import PlayerPage from "../PlayerPage";

function App(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [covered, setCovered] = useState(false);
  const [mode] = useState("dark");

  // const refVideo = React.useRef(null);

  /*   useEffect(() => {
    if (!refVideo.current) {
      return;
    }
    efVideo.current.defaultMuted = false;
    refVideo.current.muted = false;
    refVideo.current.volume = 0.5;
    setTimeout(() => refVideo.current.play(), 7000);
  }, []); */

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000 /* 14100 */);
  // }, []);

  // console.log(window.location);

  useEffect(() => {
    props.loadFeed();
  }, []);

  useEffect(() => {
    if (props.feed.title) {
      setIsLoading(false);
    }
  }, [props.feed]);

  // console.log(props.feed);

  return isLoading ? (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ height: "90vh" }}
    >
      <CircularProgress size={100} />
    </Stack>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route
          path="/player/:guid"
          element={<PlayerPage feed={props.feed} />}
        />
        <Route path="/" element={<Header setCovered={setCovered} />}>
          {/* <Route
              path="/"
              element={<Feeds covered={covered} feed={props.feed} />}
            /> */}
          <Route
            path="/"
            element={<PodcastList feed={mapFeed(props.feed)} />}
          />
          <Route
            path="/aa/:slug"
            element={<Podcast covered={covered} feed={props.feed} />}
          />
          <Route
            path=":slug"
            element={<PodcastPage feed={mapFeed(props.feed)} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { feed } = state;

  return { feed };
};

export default connect(mapStateToProps, { loadFeed })(App);

function mapFeed(feed) {
  return {
    title: feed.title._cdata,
    description: feed.description._cdata,
    link: feed.link._text,
    coverImage: feed["itunes:image"]._attributes.href,
    copyright: feed.copyright._cdata,
    items: feed.item.map((i) => ({
      guid: i.guid._text,
      title: i.title._cdata,
      subTitle: i["itunes:subtitle"]._text,
      description: i.description ? i.description._cdata : "",
      publicationDate: i.pubDate._text,
      coverImage: i["media:content"]._attributes.url,
      enclosure: {
        ...i.enclosure._attributes,
      },
      duration: i["itunes:duration"] ? i["itunes:duration"]._text : null,
      video: i.video ? i.video._text : null,
    })),
  };
}
