import React from "react";
import PropTypes from "prop-types";
import { Stack, Box, Typography } from "@mui/material";
import "./podcast-list-style.css";

export default function Header({ title, description, coverImage, copyright }) {
  return (
    <Box sx={{ padding: 0 }}>
      <Stack
        className="header"
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Box
          className="coverImageHeader"
          component="img"
          alt={title}
          src={coverImage}
        />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Typography className="titleHeader">{title}</Typography>
          <Typography className="descriptionHeader">{description}</Typography>
          <Typography className="descriptionHeader">({copyright})</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
};
