import { Box, Typography, Stack } from "@mui/material";
import React from "react";

export default function Smiles(props) {
  const [over, setOver] = React.useState(false);

  const selected = props.index === props.smileIndex;

  return (
    <Stack
      width={51}
      height={51}
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: "8px",
        bgcolor: "#313131",
      }}
    >
      <Box
        width={22}
        height={22}
        onMouseOver={() => setOver(true)}
        onMouseOut={() => setOver(false)}
        onClick={props.onClick}
        sx={{
          cursor: "pointer",
          ":hover": {
            filter: `drop-shadow(0 0 10px ${props.color});`,
          },
          ...(selected
            ? { filter: `drop-shadow(0 0 10px ${props.color});` }
            : ""),
        }}
      >
        {over || selected ? props.component : props.componentOutlined}
      </Box>
      <Typography
        variant="rating"
        sx={{
          textAlign: "center",
          color: `${props.color}`,
          mt: "5px",
          ...(over || selected
            ? { filter: `drop-shadow(0 0 10px ${props.color});` }
            : ""),
        }}
      >
        {props.smileText}
        <br />
        <b>98%</b>
      </Typography>
    </Stack>
  );
}
