import { Box, Container, Typography, Stack, Button } from "@mui/material";
import React from "react";
import ContentLogo from "../images/content_logo.svg";
import MLS from "../images/mls_icon_white.svg";
import NFL from "../images/nfl_icon_white.svg";
import USNEWS from "../images/usnews_icon_white.svg";
import MUSIC from "../images/music_icon_white.svg";
import MOVIES from "../images/movies_icon_white.svg";
import WORLDNEWS from "../images/worldnews_icon_white.svg";
import LOREM from "../images/lorem_icon_white.svg";
import { Link } from "react-router-dom";
import { keyframes } from "@emotion/react";

const containerIn = keyframes`
0%   {transform: translate3d(100%, 0, 0);}
100% {transform: translate3d(0, 0, 0); width: 100vw;}
`;

const containerOut = keyframes`
0%   {transform: translate3d(0, 0, 0);}
100% {transform: translate3d(100%, 0, 0); width: 100vw;}
`;

export default function CommandCenter(props) {
  return (
    <Container
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 11,
        height: "100vh",
        bgcolor: "#1c1c1c",
        opacity: 0.97,
        overflow: "hidden",
        animation: props.open
          ? `${containerIn} 0.5s linear 0s 1 normal`
          : `${containerOut} 0.5s linear 0s 1 normal`,
      }}
    >
      <Stack alignItems="center" sx={{ mt: 2 }}>
        <Button onClick={props.handleCloseModal}>
          <Box
            component="img"
            alt="Dot content logo"
            src={ContentLogo}
            sx={{ filter: "drop-shadow(0 0 15px #FFFEE6)" }}
          />
        </Button>
        <Typography variant="h1" sx={{ my: 0.8 }}>
          COMMAND CENTER
        </Typography>
      </Stack>

      <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="MLS logo"
            src={MLS}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            MLS
          </Typography>
        </Stack>

        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="NFL logo"
            src={NFL}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            NFL
          </Typography>
        </Stack>

        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="USNEWS logo"
            src={USNEWS}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            USNEWS
          </Typography>
        </Stack>

        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="MUSIC logo"
            src={MUSIC}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            MUSIC
          </Typography>
        </Stack>

        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="MOVIES logo"
            src={MOVIES}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            MOVIES
          </Typography>
        </Stack>

        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="WORLDNEWS logo"
            src={WORLDNEWS}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            WORLDNEWS
          </Typography>
        </Stack>

        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="LOREM IPSUM logo"
            src={LOREM}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            LOREM
          </Typography>
        </Stack>
        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="LOREM IPSUM logo"
            src={LOREM}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            LOREM
          </Typography>
        </Stack>

        <Stack
          component={Link}
          to="/feeds"
          onClick={props.handleCloseModal}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: 100,
            height: 100,
            borderRadius: "12px",
            bgcolor: "#3b3b3b",
            mt: 3,
          }}
        >
          <Box
            component="img"
            alt="LOREM IPSUM logo"
            src={LOREM}
            sx={{ width: 42, height: 42 }}
          />
          <Typography variant="h2" sx={{ color: "#fff" }}>
            LOREM
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
}
