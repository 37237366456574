import { combineReducers } from 'redux';
import xmlJs from 'xml-js';
import merge from 'lodash.merge';

const feed = (state = {}, action) => {
  if (action.response) {
    const json = xmlJs.xml2js(action.response, { compact: true, spaces: 2 });
    
    return merge({}, state, json.rss.channel);
  }

  return state;
}

const rootReducer = combineReducers({
  feed,
});

export default rootReducer
