import {
  Box,
  Container,
  Typography,
  Stack,
  List,
  ListItem,
} from "@mui/material";
import React from "react";
import Shipyard from "../images/imfStaff_shipyard.png";
import Market from "../images/imfStaff_market.png";
import FeedComment from "./helperComponents/FeedComment";
import Related from "./helperComponents/Related";
import { Link } from "react-router-dom";
import YoutubeEmbed from "./helperComponents/YoutubeEmbed";
import ReactAudioPlayer from "react-audio-player";
import { useLocation } from "react-router-dom";
import { slugify } from "../helpers/string";
import Player from "./Player";

export default function Podcast(props) {
  const location = useLocation();
  const search = location.pathname.replace("/aa/", "");

  const item = props.feed.item.find(
    (item) => slugify(item.title._text || item.title._cdata) === search
  );

  if (!item) {
    return (<></>);
    // TODO: 404
  }

  console.log(item);
  const title = item.title._text || item.title._cdata;

  const getImage = () => {
    const media = item["media:content"]._attributes;
    if (media.medium !== "image") {
      return null;
    }

    return media.url;
  };

  const getPodcast = () => {
    const media = item.enclosure._attributes;

    return media.url;
  };

  return (
    <Container
      maxWidth="sm"
      disableGutters
      sx={{
        bgcolor: "#000",
        height: props.covered ? "100vh" : "auto",
        overflow: props.covered ? "hidden" : "",
      }}
    >
      <Box sx={{ width: "100%", bgcolor: "#1A1A1A" }}>
        <Box sx={{ p: "20px" }}>
          <Typography
            variant="h1"
            fontSize="24px"
            sx={{ textAlign: "left", color: "#77EAFF", mb: "20px" }}
          >
            {title}
          </Typography>
          <Typography variant="paragraph" sx={{ textAlign: "left" }}>
            {item.pubDate._text}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ p: "20px" }}>
        <Stack alignItems="center" textAlign="left">
          <Box
            component="img"
            src={getImage()}
            width="100%"
            sx={{ mt: "27px", mb: "27px" }}
          />

          <Typography variant="h1" sx={{ color: "#fff" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.description._cdata || item.description._text,
              }}
            />
          </Typography>

          {item.video && (
            <>
              <Typography variant="h1" sx={{ color: "#fff", mt: "27px" }}>
                Video
              </Typography>
              <Box sx={{ mt: "5px" }}>
                <iframe
                  title={title}
                  className="rumble"
                  src={item.video._text}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </Box>
            </>
          )}

          <Box sx={{ mt: "5px" }}>
            <ReactAudioPlayer
              controlsList={"nodownload"}
              src={getPodcast()}
              controls
            />
          </Box>
          <Player
            src={getPodcast()}
            image={getImage()}
            feedTitle={props.feed.title._cdata}
            link="/asd"
            title={title}
          />

          <Typography variant="h1" sx={{ color: "#fff", mt: "27px" }}>
            Audio
          </Typography>
        </Stack>
        <FeedComment color="#77EAFF" />
      </Box>
    </Container>
  );
}
