import {
  Box,
  Container,
  Typography,
  Stack,
  Button,
  TextField,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#89e100",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function UserProfile(props) {
  const [value, setValue] = React.useState("User Name");
  const [emailValue, setEmailValue] = React.useState("E-mail address");
  const [postalValue, setPostalValue] = React.useState("Postal address");
  const [radioValue, setRadioValue] = React.useState("yes");
  const [switchValue, setSwitchValue] = React.useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setSwitchValue(!switchValue);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        position: "absolute",
        width: "100%",
        height: "100vh",
        bgcolor: "#000",
      }}
    >
      <Stack alignItems="center" sx={{ my: 2 }}>
        <Typography variant="h1" sx={{ my: 1, color: "#77EAFF" }}>
          USER PROFILE
        </Typography>
      </Stack>

      <Stack
        justifyContent="flex-start"
        sx={{ bgcolor: "#1c1c1c", borderRadius: "20px" }}
      >
        <Stack sx={{ mb: 4, mt: 3, mx: 2 }}>
          <Typography variant="p" sx={{ color: "#fff", mb: 1 }}>
            <b>User Name</b>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              name="name"
              value={value}
              onFocus={() => setValue(() => "")}
              onBlur={() => setValue(() => "User Name")}
              onChange={(event) => setValue(event.target.value)}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: {
                  color: "#000",
                  backgroundColor: "#888888",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  maxHeight: "45px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#77EAFF",
                  },
                },
              }}
            />
            <EditIcon
              sx={{
                color: "#77EAFF",
                ml: 1,
                my: 0.5,
                p: 0.5,
                border: "1px solid #77EAFF",
                borderRadius: "25px",
              }}
            />
          </Box>
        </Stack>

        <Stack sx={{ mb: 4, mt: 0, mx: 2 }}>
          <Typography variant="p" sx={{ color: "#fff", mb: 1 }}>
            <b>E-mail address</b>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              name="email"
              value={emailValue}
              onFocus={() => setEmailValue(() => "")}
              onBlur={() => setEmailValue(() => "E-mail address")}
              onChange={(event) => setValue(event.target.value)}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: {
                  color: "#000",
                  backgroundColor: "#888888",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  maxHeight: "45px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#77EAFF",
                  },
                },
              }}
            />
            <EditIcon
              sx={{
                color: "#77EAFF",
                ml: 1,
                my: 0.5,
                p: 0.5,
                border: "1px solid #77EAFF",
                borderRadius: "25px",
              }}
            />
          </Box>
        </Stack>

        <Stack sx={{ mb: 4, mt: 0, mx: 2 }}>
          <Typography variant="p" sx={{ color: "#fff", mb: 1 }}>
            <b>Postal address</b>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              name="postal"
              value={postalValue}
              onFocus={() => setPostalValue(() => "")}
              onBlur={() => setPostalValue(() => "Postal address")}
              onChange={(event) => setValue(event.target.value)}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: {
                  color: "#000",
                  backgroundColor: "#888888",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  maxHeight: "45px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#77EAFF",
                  },
                },
              }}
            />
            <EditIcon
              sx={{
                color: "#77EAFF",
                ml: 1,
                my: 0.5,
                p: 0.5,
                border: "1px solid #77EAFF",
                borderRadius: "25px",
              }}
            />
          </Box>
        </Stack>

        <Typography
          variant="p"
          sx={{ color: "#fff", m: "8px auto", textAlign: "center" }}
        >
          <b>Would You also subscribe to Content in printed version?</b>
        </Typography>

        <FormControl sx={{ ml: 4 }}>
          <RadioGroup
            aria-labelledby="controlled-radio-buttons-group"
            name="radio-buttons-group"
            value={radioValue}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  sx={{
                    color: "#fff",
                    "&.Mui-checked": {
                      "&, & + .MuiTypography-root": {
                        color: "#77EAFF",
                        filter: "drop-shadow(0 0 15px #77EAFF)",
                      },
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  Yes, I'd subscribe for the printed version
                </Typography>
              }
              sx={{ fontSize: "11px" }}
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  sx={{
                    color: "#fff",
                    "&.Mui-checked": {
                      "&, & + .MuiTypography-root": {
                        color: "#77EAFF",
                        filter: "drop-shadow(0 0 15px #77EAFF)",
                      },
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: "11px" }}>
                  No, I will not subscribe for the printed version
                </Typography>
              }
              sx={{ fontSize: "11px" }}
            />
          </RadioGroup>
        </FormControl>

        <Typography
          variant="p"
          sx={{ color: "#fff", m: "8px auto", textAlign: "center" }}
        >
          <b>Would You subscribe our newsletter?</b>
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              icon={<RadioButtonUncheckedIcon sx={{ color: "#fff" }} />}
              checkedIcon={<CheckCircleIcon sx={{ color: "green" }} />}
              sx={{
                "&.Mui-checked": {
                  "& + .MuiTypography-root": {
                    color: "#77EAFF",
                    filter: "drop-shadow(0 0 15px #77EAFF)",
                  },
                },
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: "11px" }}>
              Yes, I'd subscribe for the newsletter
            </Typography>
          }
          sx={{ ml: "21px" }}
        />

        <Typography
          variant="p"
          sx={{ color: "#fff", m: "8px auto", textAlign: "center" }}
        >
          <b>Do You want to receive notifications from Content?</b>
        </Typography>

        <FormControlLabel
          value={switchValue}
          control={<IOSSwitch sx={{ m: 1 }} onChange={handleSwitchChange} />}
          label={
            !switchValue ? (
              <Typography sx={{ fontSize: "11px" }}>No, thank you.</Typography>
            ) : (
              <Typography sx={{ fontSize: "11px", color: "#89e100" }}>
                Yes, I'd like to receive notifications
              </Typography>
            )
          }
          labelPlacement="start"
          sx={{ justifyContent: "space-between", maxWidth: "290px", ml: 4 }}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ my: 3, mx: "auto", width: 300 }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: "#89e100" }}
            onClick={() => navigate(-1)}
          >
            Done
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
