import React, { createRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Stack,
  Slider,
  IconButton,
  Grid,
  ButtonGroup,
  Button,
} from "@mui/material";

import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import Replay10Icon from "@mui/icons-material/Replay10";
import Forward10Icon from "@mui/icons-material/Forward10";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Embed from "./Embed";
import { ShareLinks } from "./PodcastList/ShareLinks";
import "./player-style.css";

const Widget = styled("div")(({ theme }) => ({
  padding: 5,
  borderRadius: 16,
  width: "100%",
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
  backgroundColor: "#30343c",
  // backdropFilter: "blur(40px)",
}));

const CoverImage = styled("div")({
  // width: 100,
  // height: 100,
  objectFit: "cover",
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.08)",
  "& > img": {
    width: "100%",
  },
});

const TinyText = styled(Typography)({
  fontSize: "1rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const AudioSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
  height: 8,
  "& .MuiSlider-thumb": {
    width: 8,
    height: 8,
    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    "&:before": {
      boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
    },
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${
        theme.palette.mode === "dark"
          ? "rgb(255 255 255 / 16%)"
          : "rgb(0 0 0 / 16%)"
      }`,
    },
    "&.Mui-active": {
      width: 20,
      height: 20,
    },
  },
  "& .MuiSlider-rail": {
    opacity: 0.28,
  },
}));

function PlayerControl(props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={3} sm={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: -1,
          }}
        >
          <IconButton
            aria-label={props.paused ? "play" : "pause"}
            onClick={() => props.onTogglePlay()}
          >
            {props.paused ? (
              <PlayCircleFilledWhiteIcon
                sx={{ fontSize: "4.5rem" }}
                htmlColor="#fff"
              />
            ) : (
              <PauseCircleFilledIcon
                sx={{ fontSize: "4.5rem" }}
                htmlColor="#fff"
              />
            )}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={9} sm={11} justifyContent="center" alignItems="center">
        <Box>
          <AudioSlider
            aria-label="time-indicator"
            size="small"
            value={props.position}
            min={0}
            step={1}
            max={props.duration}
            onChange={(_, value) => props.onHandlePositionChange(value)}
            onChangeCommitted={() => props.onApplyPosition()}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
            }}
          >
            <TinyText>{props.formatDuration(props.position)}</TinyText>
            <TinyText>
              -{props.formatDuration(props.duration - props.position)}
            </TinyText>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default function Player(props) {
  const audioPlayerRef = createRef();
  const hasControls = false;
  const [listeners, setListeners] = React.useState([]);

  const theme = useTheme();
  const [duration, setDuration] = React.useState(0); // seconds
  const [position, setPosition] = React.useState(0);
  const [changingPosition, setChangingPosition] = React.useState(false);
  const [paused, setPaused] = React.useState(true);
  const [hasShareOverlay, setHasShareOverlay] = useState(false);

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    // const secondLeft = value - minute * 60;
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }
  const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";

  const onLoadedMetadata = (event) => {
    console.log("meta", event);
    setDuration(event.target.duration);
  };

  const onLoadedData = (event) => {};

  const onTimeupdate = (event, chs) => {
    console.log(changingPosition, event, chs);
    if (!changingPosition) {
      setPosition(event.target.currentTime);
    }
  };

  const onProgress = (event) => {
    // console.log('progress', event);
  };

  const onError = (event) => {};

  const onEnded = (event) => {};

  const addListener = (event, cb) => {
    audioPlayerRef.current.addEventListener(event, cb);

    setListeners([...listeners, { event, cb }]);
  };

  const togglePlay = () => {
    paused ? audioPlayerRef.current.play() : audioPlayerRef.current.pause();

    setPaused(!paused);
  };

  const handlePositionChange = (value) => {
    console.log("handlePositionChange", value);
    setChangingPosition(true);
    setPosition(value);
  };

  const applyPosition = () => {
    console.log("applyPosition", position, changingPosition);
    const audio = audioPlayerRef.current;
    if (audio) {
      audio.currentTime = position;
    }
  };

  useEffect(() => {
    addListener("timeupdate", (event) => {
      onTimeupdate(event, changingPosition);
    });
    addListener("progress", onProgress);
    addListener("error", onError);
    addListener("ended", onEnded);
    addListener("loadeddata", onLoadedData);
    addListener("loadedmetadata", onLoadedMetadata);

    return () => {
      const audio = audioPlayerRef.current;

      if (audio) {
        listeners.forEach((item) => {
          audio.removeEventListener(item.event, item.cb);
        });
      }
    };
  }, []);

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      {props.compact ? (
        <Box sx={{ p: 2.5 }}>
          <PlayerControl
            onApplyPosition={applyPosition}
            onHandlePositionChange={handlePositionChange}
            onTogglePlay={togglePlay}
            formatDuration={formatDuration}
            position={position}
            duration={duration}
            paused={paused}
          />
        </Box>
      ) : (
        <Widget>
          <Grid
            container
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={3} sm={2}>
              <Box>
                <CoverImage>
                  <a href={props.link} target="_blank" rel="noreferrer">
                    <img
                      style={{
                        maxWidth: "140px",
                        width: "100%",
                        height: "100%",
                      }}
                      alt=""
                      src={props.image}
                    />
                  </a>
                </CoverImage>
              </Box>
            </Grid>
            {!hasShareOverlay && (
              <Grid item xs={9} sm={10} transition-style="out:wipe:down">
                <Box>
                  <Stack>
                    <Box>
                      <Typography
                        color="text.secondary"
                        fontWeight={500}
                        fontSize={14}
                      >
                        {props.feedTitle}
                      </Typography>
                      {/* <Typography noWrap sx={{ width: "220px" }}>
                    <b>{props.title}</b>
                  </Typography> */}
                      <Typography fontSize={13} sx={{ maxWidth: "100%" }}>
                        <b>{props.title}</b>
                      </Typography>
                    </Box>
                    <PlayerControl
                      onApplyPosition={applyPosition}
                      onHandlePositionChange={handlePositionChange}
                      onTogglePlay={togglePlay}
                      formatDuration={formatDuration}
                      position={position}
                      duration={duration}
                      paused={paused}
                    />
                    <Stack direction="row" spacing={2}>
                      <Box>
                        <Button
                          variant="text"
                          onClick={() => setHasShareOverlay(true)}
                        >
                          Share
                        </Button>
                        <Button
                          variant="text"
                          component="a"
                          href={props.src}
                          target="_blank"
                        >
                          Download
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            )}

            {hasShareOverlay && (
              <Grid
                className="shareOverlay"
                item
                xs={9}
                sm={10}
                transition-style="in:wipe:up"
                sx={
                  {
                    // left: 175,
                    // borderRadius: "0 30px 30px 0",
                    // // position: "absolute",
                    // width: "100%",
                    // height: "100%",
                    // visibility: "visible",
                  }
                }
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography
                      noWrap
                      fontSize={16}
                      sx={{ maxWidth: "100%", fontWeight: 700 }}
                    >
                      Share
                    </Typography>
                    <IconButton onClick={() => setHasShareOverlay(false)}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Stack>
                  <Embed
                    feedLink={props.feedLink}
                    podcast={{ guid: props.guid, title: props.title }}
                  />
                  <ShareLinks />
                </Stack>
              </Grid>
            )}
          </Grid>
        </Widget>
      )}
      <audio
        ref={audioPlayerRef}
        controls={hasControls}
        src={props.src}
        preload="motadata"
      />
    </Box>
  );
}
