export function getTheme(mode) {
  switch (mode) {
    default:
      return getDarkTheme();
  }
}

export function getDarkTheme() {
  return {
    typography: {
      allVariants: {
        color: "#fff",
        fontFamily: "Open Sans",
        fontWeight: 500,
      },
      p: { fontSize: "10px" },
      h1: {
        fontSize: "14px",
        fontWeight: 700,
      },
      h2: {
        fontSize: "12px",
        fontWeight: 700,
        color: "#77EAFF",
        display: "flex",
        justifyContent: "center",
      },
      h3: {
        fontSize: "11px",
        fontWeight: 700,
        color: "#fff",
        display: "flex",
        justifyContent: "center",
      },
      h4: {
        fontWeight: 700,
        fontSize: "11px",
        color: "#fff",
      },
      h5: {
        fontWeight: 700,
        fontSize: "10px",
        color: "#fff",
      },
      paragraph: {
        fontSize: "9px",
        color: "#fff",
      },
      rating: {
        fontSize: "7px",
      },
    },
    button: {
      allVariants: {
        fontWeight: 500,
      },
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#fff",
        text: "#fff",
        secondaryText: "#808080",
        link: "#1976D2",
        filterBarBg: "#141416",
        appBarBg: "#000",
        menuBar: "#141416",
        SelectedZoneContentViewBg: "#141416",
        defaultBg: "#121212",
      },
      secondary: {
        main: "#0000FF",
        filterBarButtonColor: "#808080",
        filterBarButtonHoverColor: "#fff",
        filterBarButtonHoverBgColor: "#393947",
        pendingBadgeBg: "#f8d300",
        requestBadgeBg: "#00ff00",
        friendBadgeBg: "#ee00ff",
      },
      subscriptionSelector: {
        listItemText: "#FF0000",
      },
      profile: {
        main: "#fff",
        text: "#fff",
        textBg: "#1b1a1c",
        profileBg: "#292833",
        uploadButtonColor: "#fff",
        uploadButtonBg: "#6000fd",
        instaIcon: "#fff",
        twitterIcon: "#fff",
        fbIcon: "#fff",
        generalButton: "#fff",
        generalButtonBg: "#6000fd",
      },
      containers: {
        cardBg: "#212227",
        profileBg: "#212227",
      },
    },
  };
}
