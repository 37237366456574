import React, { useEffect, useState } from "react";
import { Stack, Box, Paper, InputBase, IconButton, Divider, Tooltip, Typography, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function Embed(props) {
  // const [podcast, setPodcast] = useState({});
  const [playerUrl, setPlayerUrl] = useState('');
  const [iframeCode, setIframeCode] = useState('');

  useEffect(() => {
    const url = new URL(`/player/${props.podcast.guid}`, props.feedLink);

    setIframeCode(`<iframe title="${props.podcast.title}" height="150" width="100%" style="border: none;" scrolling="no" src="${url.toString()}" allowfullscreen=""></iframe>`)
  }, [props.feedLink, props.podcast]);

  return (
    <Box
      component="div"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <Typography>
        EMBED
      </Typography>
      {/* <InputBase
        variant="outlined"
        sx={{ ml: 1, flex: 1 }}
        readOnly
        value={iframeCode}
        inputProps={{ 'aria-label': 'iframe code' }}
      /> */}
      <TextField variant="outlined" value={iframeCode}
        sx={{ ml: 1, flex: 1 }}
        readOnly
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <Tooltip title="Copy">
        <IconButton sx={{ p: '10px' }} aria-label="copy" onClick={() => {navigator.clipboard.writeText(iframeCode)}}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
