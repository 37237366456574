import AppBar from "@mui/material/AppBar";
import ListItem from "@mui/material/ListItem";
import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { withStyles } from "@mui/styles";
import { Link, Outlet } from "react-router-dom";
import { ReactComponent as HeaderLogo } from "../images/content_logo.svg";
import UserAvatar from "../images/user_avatar_header.png";
import { Box, Typography } from "@mui/material";
import CommandCenter from "./CommandCenter";
import { useLocation } from "react-router-dom";
import { keyframes } from "@emotion/react";
import useScrollingUp from "../hooks/useScrollingUp";

const headerOut = keyframes`
0%   {transform: translate3d(0, 0, 0);}
100% {transform: translate3d(0, -100%, 0);}
`;

const headerIn = keyframes`
0%   {transform: translate3d(0, -100%, 0);}
100% {transform: translate3d(0, 0, 0);}
`;

const scrollUp = keyframes`
0%   {transform: translate3d(0, -50%, 0);}
100% {transform: translate3d(0, 10%, 0);}
`;

const StyledAppBar = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.appBarBg,
    height: 65,
    "& .MuiToolbar-root": {
      padding: "4px 0",
    },
    backgroundImage: "none",
  },
}))(AppBar);

const HeaderListItem = withStyles(() => ({
  root: {
    width: "auto",
    textAlign: "right",
    display: "flex",
    justifyContent: "center",
    padding: "4px 0",
  },
}))(ListItem);

const Header = (props) => {
  const [open, setOpen] = React.useState(false);
  const [showHeader, setShowHeader] = React.useState(true);

  const isScrollingUp = useScrollingUp();
  const location = useLocation();

  const handleOpenModal = () => {
    setOpen(true);
    props.setCovered(true);
    setTimeout(() => {
      setShowHeader(false);
    }, 500);
  };

  const handleCloseModal = () => {
    setOpen(false);
    props.setCovered(false);
    setTimeout(() => {
      setShowHeader(true);
    }, 500);
  };

  return <Outlet />;

  return (
    <StyledEngineProvider injectFirst>
      {showHeader ? (
        <StyledAppBar
          sx={{
            animation: open
              ? `${headerOut} 0.5s linear 0s 1 normal`
              : isScrollingUp
              ? `${scrollUp} 0.5s linear 0s 1 normal`
              : showHeader
              ? `${headerIn} 0.5s linear 0s 1 normal`
              : "none",
            position: isScrollingUp ? "fixed" : "relative",
          }}
        >
          <Toolbar
            sx={{
              width: "100%",
              minHeight: "unset",
              justifyContent: "flex-end",
            }}
          >
            {location.pathname !== "/profile" ? (
              <HeaderListItem
                component={Link}
                to="/"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Box
                  component="img"
                  src="https://bn-podcast-backup.rhnd.io/images/AVN_YT_Banner_Pandemic_Live_-_smaller.png"
                  height={45}
                  width={40}
                />
              </HeaderListItem>
            ) : (
              <HeaderListItem
                component={Link}
                to="/"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Box
                  component="img"
                  src="https://bn-podcast-backup.rhnd.io/images/AVN_YT_Banner_Pandemic_Live_-_smaller.png"
                  height={45}
                  width={40}
                />
              </HeaderListItem>
            )}
          </Toolbar>
        </StyledAppBar>
      ) : (
        <CommandCenter handleCloseModal={handleCloseModal} open={open} />
      )}
      <Outlet />
    </StyledEngineProvider>
  );
};

export default Header;
