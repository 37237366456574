import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

const stack = keyframes`
0%   {transform: translateY(100%); opacity: 0;}
30%   {transform: translateY(0); opacity: 1;}
80%   {transform: translateX(0); opacity: 1; overflowX: hidden;}
100% {transform: translateX(100%); opacity: 0; overflowX: hidden; display: none;}
`;

export default function SocialActivities(props) {
  return (
    <Stack
      direction="row"
      sx={{
        mb: 2,
        animation: `${stack} 2.5s linear 0s 1 normal forwards`,
      }}
    >
      <Box component="img" alt={props.alt} src={props.avatar} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          bgcolor: "#1a1a1a",
          ml: 2,
          width: "-webkit-fill-available",
          height: "36px",
          borderRadius: "0 8px 8px 8px",
        }}
      >
        <Typography variant="h4" fontWeight={500} sx={{ ml: 1 }}>
          {props.text}
        </Typography>
      </Box>
    </Stack>
  );
}
