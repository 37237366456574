import PropTypes from "prop-types";

export const podcastListPropTypes = {
  feed: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        guid: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        publicationDate: PropTypes.string.isRequired,
        coverImage: PropTypes.string.isRequired,
        enclosure: PropTypes.shape({
          length: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
};
